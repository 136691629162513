import * as React from "react";
import {
  Wrap
} from "@chakra-ui/react";


interface Props {
  children: React.ReactNode
};


export const GuaranteeWrap: React.FC<Props> = ( props ) => {
  return (
    <Wrap
      direction="row"
      justify="center"
      align="baseline"
      spacing={ 6 }
      w="100%"
      padding={{
        base: '64px 0',
        lg: '129px 0 143px'
      }}
    >
      { props.children }
    </Wrap>
  )
}
