import * as React from "react";
import { graphql } from "gatsby";

import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import { CallToAction } from "../components/CallToAction";
import { GuaranteeCard, PestCard } from "../components/Card";
import { TestimonialsCarousel } from "../components/Carousel";
import { GuaranteeFlexbox, TermitesFlexbox } from "../components/Flexbox";
import { CommonPestsGrid } from "../components/Grid";
import { ServiceHero } from "../components/Hero";
import { ProcessItem, Stack } from "../components/Stack";
import { GuaranteeWrap } from "../components/Wrap";


interface Props {
  data: any
};


const ServiceTemplate: React.FC<Props> = ( props ) => {
  const {
    mdx: {
      frontmatter: {
        title,
        pageHeading,
        pageDescription,
        heroImage,
        guaranteeImage,
        termitesImage,
        guaranteeCards
      }
    },
    pests,
    process,
    testimonials
  } = props.data;

  return (
    <Layout>
      <Head
        title={ title }
      />

      <SectionWrapper
        background="background.linen"
      >
        <SectionContainer>
          <ServiceHero
            heading={ pageHeading }
            description={ pageDescription }
            image={ heroImage.childImageSharp.gatsbyImageData }
            alt=""
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="100px 0 128px"
      >
        <SectionContainer>
          <SectionContent
            heading="Our Working Process"
            description={[
              "Our process follows specific steps and adapts accordingly for project specifics."
            ]}
            textAlign="center"
          />
          <Stack
            align="center"
            spacing={ 12 }
          >
            { process.edges.map(( item: any, _index: number ) => (
              <ProcessItem
                order={ item.node.frontmatter.order }
                icon={ item.node.frontmatter.icon }
                heading={ item.node.frontmatter.heading }
                list={ item.node.frontmatter.list }
              />
            ))}
          </Stack>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
      >
        <SectionContainer>
          <GuaranteeFlexbox
            heading="Service Guarantee"
            description={[
              "Vester Pest Control is simple -  your contracted pest is gone for good - guaranteed. You will receive a full refund if you are not pleased with the services delivered to you within the guarantee period!",
              "Providing high-quality services and ensuring the safety of our customers and their patrons from one county to the next."
            ]}
            image={ guaranteeImage.childImageSharp.gatsbyImageData }
            alt=""
          />
          <GuaranteeWrap>
            { guaranteeCards.map(( card: any, _index: number ) => (
              <GuaranteeCard
                key={ _index }
                heading={ card.heading }
                description={ card.description }
                list={ card.list }
              />
            ))}
          </GuaranteeWrap>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="linear-gradient(white 0%, white 57%, #F94E1911 57%, #F94E1911 100%)"
        padding="64px 0 57px"
      >
        <SectionContainer>
          <CommonPestsGrid
            heading="Common Pests"
            description={[
              "At Vester, we know our pests, and you can too. Explore our pest library to learn how to identify common pests."
            ]}
          >
            { pests.edges.map(( pest: any, _index: number ) => (
              <PestCard
                heading={ pest.node.frontmatter.heading }
                image={ pest.node.frontmatter.image.childImageSharp.gatsbyImageData }
              />
            ))}
          </CommonPestsGrid>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
        padding="155px 0"
      >
        <SectionContainer>
          <TermitesFlexbox
            direction="row"
            heading="Complete Termite Extermination"
            description={[
              "Vester Pest Control is the number one choice for San Diego County termite inspections and termite treatments. We have over 20 years of combined experience and have helped over 20,000 homeowners and businesses with their termite infestations and other pest control needs.",
              "Contact us directly to receive your specialized termite and wood-destroying organisms estimate."
            ]}
            image={ termitesImage.childImageSharp.gatsbyImageData }
            imageBackground="white"
            imageMaxW={{
              base: '80%',
              sm: '320px',
              md: '100%'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading="Our service and professionals have earned over 3,700+ reviews!"
            headingWidth={{
              base: '100%',
              lg: '700px'
            }}
            headingMargin="0 auto"
            description={[
              "Vester Pest Control has helped thousands of property owners over the past 20 years. Here are what some of our customers have to say."
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
        </SectionContainer>
        <TestimonialsCarousel
          nodes={ testimonials.edges }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading="Request a Free Quote"
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query Service($id: String) {
    mdx(id: {
      eq: $id
    }) {
      id
      frontmatter {
        title
        pageHeading
        pageDescription
        heroImage {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        guaranteeImage {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        termitesImage {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        guaranteeCards {
          heading
          description
          list
        }
      }
    }

    pests: allMdx(
      filter: {
        frontmatter: {
          template: {
            eq: "pest"
          }
          featured: {
            eq: true
          }
        }
      }
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }

    process: allMdx(
      filter: {
        frontmatter: {
          template: {
            eq: "process"
          }
        }
      }
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            list
            icon
            order
            template
          }
        }
      }
    }

    testimonials: allMdx(
      filter: {
        frontmatter: {
          template: {
            eq: "testimonial"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            source
            reviewer
            review
          }
        }
      }
    }
  }
`;


export default ServiceTemplate;
